import { useResizeColumn } from '@blissbook/ui/hooks/useResizeColumn'
import { cx } from '@emotion/css'
import React from 'react'
import { InfiniteContextLoading } from '../infinite'

export type TableHeaderProps = React.HTMLAttributes<HTMLDivElement>

export const Header = React.forwardRef<HTMLDivElement, TableHeaderProps>(
  ({ className, ...props }, ref) => {
    return (
      <div {...props} className={cx('table-header', className)} ref={ref} />
    )
  },
)

export type HeadingProps = React.HTMLAttributes<HTMLDivElement> & {
  onChangeWidth?: (width: number) => void
}

export function Heading({
  children,
  className,
  onChangeWidth,
  ...props
}: HeadingProps) {
  const { setHeaderNode, setResizeNode } = useResizeColumn(onChangeWidth)
  return (
    <>
      <div
        {...props}
        className={cx('table-heading', className)}
        ref={setHeaderNode}
      >
        {children}
      </div>
      {onChangeWidth && <HeadingDivider ref={setResizeNode} />}
    </>
  )
}

export type HeadingDividerProps = {
  className?: string
  disabled?: boolean
}

export const HeadingDivider = React.forwardRef<
  HTMLDivElement,
  HeadingDividerProps
>(({ className }, ref) => {
  return (
    <div
      className={cx(
        'tw-flex tw-items-center tw-justify-center tw-cursor-ew-resize tw-w-3 tw-min-w-3 tw-max-w-3 -tw-mx-1.5 tw-py-1 tw-z-10',
        className,
      )}
      ref={ref}
    >
      <div className='tw-h-full tw-bg-gray-100 -tw-mx-px tw-px-px' ref={ref} />
    </div>
  )
})

export type BodyProps = React.HTMLAttributes<HTMLDivElement>

export const Body = React.forwardRef<HTMLDivElement, BodyProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div {...props} className={cx('table-body', className)} ref={ref}>
        {children}

        <InfiniteContextLoading className='table-cell'>
          Loading
        </InfiniteContextLoading>
      </div>
    )
  },
)

export type CellProps = React.HTMLAttributes<HTMLDivElement>

export function Cell({ className, ...props }: CellProps) {
  return <div {...props} className={cx('table-cell', className)} />
}

export type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  selected?: boolean
}

export const Row = React.forwardRef<HTMLDivElement, RowProps>(
  ({ active, className, selected, ...props }, ref) => {
    const { onClick } = props
    return (
      <div
        {...props}
        className={cx(
          'table-row tw-group',
          onClick && 'tw-cursor-pointer',
          { active, selected },
          className,
        )}
        ref={ref}
      />
    )
  },
)

export type TableProps = React.HTMLAttributes<HTMLDivElement>

export function Table({ className, ...props }: TableProps) {
  return <div {...props} className={cx('table', className)} />
}
